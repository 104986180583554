
// material-ui
import logo from 'assets/images/logo.png';


// ==============================|| LOGO ||============================== //

const LogoMain = () => {
  return (
    <>
      <img src={logo} alt="Instalily" width="200" />
    </>
  );
};

export default LogoMain;
