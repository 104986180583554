
// material-ui
//import { Box, useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';

// project import
import Profile from './Profile';

//import Search from './Search';
//import Message from './Message';
//import Notification from './Notification';
//import MobileSection from './MobileSection';
//import MegaMenuSection from './MegaMenuSection';


// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  //const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  //const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <> 
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <Profile />
    </Box>

      {/* {!matchesXs && <Search />} */}
      {/* {!matchesXs && megaMenu} */}
      {/* {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />} */}

      {/* <Notification /> */}
      {/* <Message /> */}
      {/* {matchesXs && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
