// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

function App() {

  return (
    <ThemeCustomization>
      <Locales>
        <ScrollTop>
            <>
              <Notistack>
              <Routes />
              <Snackbar />
            </Notistack>
          </>
        </ScrollTop>
      </Locales>
    </ThemeCustomization>
  )
}

export default App;

