import { useNavigate } from 'react-router';

// material-ui
import { Box, Tooltip, Typography } from '@mui/material';

// project import
import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';

// assets
import { LogoutOutlined } from '@ant-design/icons';

import { useAuth0 } from "@auth0/auth0-react";

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const handleLogout = async () => {
    try {
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  const userName = localStorage.getItem('userName');

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="subtitle1" sx={{ mr: 2 }}>
        {userName}
      </Typography>
      <Tooltip title="Logout">
        <IconButton size="large" sx={{ color: 'text.primary' }} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
          <LogoutOutlined />
        </IconButton>
      </Tooltip>
    </Box>
  );
};


export default Profile;
