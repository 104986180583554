
// material-ui
import logo from 'assets/images/logo_mini.png';


// ==============================|| LOGO ||============================== //

const LogoIcon = () => {
  return (
    <>
      <img src={logo} alt="Instalily" width="30" />
    </>
  );
};

export default LogoIcon;
