// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  LineChartOutlined
} from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  LineChartOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const dashboard = {
  id: 'Dashboard',
  title: <FormattedMessage id="Dashboard" />,
  type: 'group',
  children: [
    {
      id: 'Insights',
      title: <FormattedMessage id="Ad Insights" />,
      type: 'item',
      url: 'dashboard/advertiser',
      icon: icons.LineChartOutlined
    }
    /* {
      id: 'Platform',
      title: <FormattedMessage id="Platform" />,
      type: 'item',
      url: 'dashboard/platform',
      icon: icons.ChromeOutlined
    },
    {
      id: 'Ad',
      title: <FormattedMessage id="Ad Details" />,
      type: 'item',
      url: 'dashboard/addetails',
      icon: icons.ChromeOutlined
    } */
  ]
};

export default dashboard;