// project import
import other from './other';
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, other]
};

export default menuItems;
