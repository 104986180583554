import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router-dom';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render 
const DashboardPlatform = Loadable(lazy(() => import('pages/dashboard/platform')));
const DashboardAdvertiser = Loadable(lazy(() => import('pages/dashboard/advertiser')));
const DashboardAdDetails = Loadable(lazy(() => import('pages/dashboard/ad')));

const SlackInfo = Loadable(lazy(() => import('pages/documentation/slack')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        // Redirect root to /dashboard/advertiser
        {
          path: '/',
          element: <Navigate to="/dashboard/advertiser" />
        },
        {
          path: 'dashboard',
          children: [
            {
              path: 'advertiser',
              element: <DashboardAdvertiser />
            },
            {
              path: ':id', 
              element: <DashboardPlatform />
            },
            {
              path: 'details',
              children: [
                {
                  path: ':ad',
                  element: <DashboardAdDetails />
                }
              ]
            },
          ]
        },
        {
          path: 'documentation',
          children: [
            {
              path: 'slack',
              element: <SlackInfo />
            }
        ]
      },
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
